import image1 from "../assets/image1-t.jpg.jpg";
import image2 from "../assets/image2-t.png";
import image3 from "../assets/t-image3.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'PABITRA SARKAR',
    status : 'STUDENT'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'SIDDHANT RAI SIKHAND',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'SOHAM BAKSHI',
    status: "CREATOR"
  }
];
